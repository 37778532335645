.copperhead-logo {
  animation: distort 3s ease-in-out infinite;
}

@keyframes distort {
  10% {
    transform: translate(0px, 0) skew(0deg) rotateZ(90deg);
    -webkit-transform: translate(0px, 0) skew(0deg) rotateZ(90deg);
  }
  33% {
    transform: translate(0px, 0) skew(0deg) rotateZ(360deg);
    -webkit-transform: translate(0px, 0) skew(0deg) rotateZ(360deg);
  }
  66% {
    transform: rotateZ(-1080deg);
    -webkit-transform: rotateZ(-1080deg);
  }
  100% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
  }
}
