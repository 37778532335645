::selection {
  color:whitesmoke;
  background-color: #63a528;
}
/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #202020;
  box-shadow: 0 4px 8px #0f0f0f;
}

::-webkit-scrollbar-thumb {
  background: #63a528;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f7543;
}

a:link {
  text-decoration: none;
}

/* visited link */
a:visited {
  text-decoration: none;
}

/* mouse over link */
a:hover {
  text-decoration: none;
}

/* selected link */
a:active {
  text-decoration: none;
}